<template>
  <v-navigation-drawer v-if="project"
    color="#547cbc"
    app
    dark
    permanent
    :mini-variant.sync="mini"
    stateless
  >
    <v-card
      class="d-flex align-start ma-0"
      tile
      height="92"
      color="grey lighten-5"
      elevation="0"
    >
      <v-row no-gutters>
        <v-col cols="10" v-show="!mini">
          <v-img
            class="ma-1 ml-2"
            alt="Branding Technology"
            max-width="176"
            src="../../../assets/images/btlogo.png"
          ></v-img>
        </v-col>
        <v-col cols="2">
          <v-btn icon @click.stop="mini = !mini" color="blue darken-4">
            <v-icon v-show="mini">mdi-chevron-right</v-icon>
            <v-icon v-show="!mini">mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" v-show="!mini">
          <user-information></user-information>
        </v-col>
      </v-row>
    </v-card>

    <v-divider></v-divider>

    <v-list
    nav
    dense
    >
      <v-list-item-group
        v-model="selectedTab"
        :color="selectedTabColor"
      >
        <v-list-item-with-link
          color="blue lighten-4"
          icon="mdi-ear-hearing"
          title="BPシート"
          :status="brandTotalStatus"
          @click="changeTab(0)"
        >
        </v-list-item-with-link>

        <v-list-item-with-link
          color="blue lighten-4"
          icon="mdi-google-analytics"
          title="戦略設計"
          :status="flameworkTotalStatus"
          @click="changeTab(1)"
        >
        </v-list-item-with-link>

        <v-list-item-with-link
          color="blue lighten-4"
          icon="mdi-clipboard-account"
          title="ユーザ理解"
          :status="userTotalStatus"
          @click="changeTab(2)"
        >
        </v-list-item-with-link>

        <v-list-item-with-link
          color="blue lighten-4"
          icon="mdi-palette"
          title="デザイン要件"
          :status="designTotalStatus"
          @click="changeTab(3)"
        >
        </v-list-item-with-link>

        <v-list-item-with-link
          color="blue lighten-4"
          icon="mdi-target"
          title="プランニング"
          :status="planTotalStatus"
          @click="changeTab(4)"
        >
        </v-list-item-with-link>
      </v-list-item-group>
    </v-list>

    <v-expand-transition>
      <project-description
        v-show="!mini"
      ></project-description>
    </v-expand-transition>
    <v-btn-with-external-link
      v-show="!mini"
      :href="project.siteUrl"
      text="対象URL"
      :disabled="project.siteUrl ? false : true"
    ></v-btn-with-external-link>
    <v-btn-with-external-link
      v-show="!mini"
      :href="project.analyticsUrl"
      text="数値データ"
      :disabled="project.analyticsUrl ? false : true"
    ></v-btn-with-external-link>
    <v-btn-with-external-link
      v-show="!mini"
      :href="project.salesforceUrl"
      text="Salesforce"
      :disabled="project.salesforceUrl ? false : true"
    ></v-btn-with-external-link>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'

import UserInformation from '../molecules/UserInformation'
import VListItemWithLink from '../atoms/VListItemWithLink'
import ProjectDescription from '../organisms/ProjectDescription'
import VBtnWithExternalLink from '../molecules/VBtnWithExternalLink'
export default {
  components: {
    UserInformation,
    VListItemWithLink,
    ProjectDescription,
    VBtnWithExternalLink,
  },
  data() {
    return {
      mini: true,
      selectedTab: 0,
      selectedTabColor: 'white',
    }
  },
  computed: {
    ...mapGetters([
      'project',
      'brandTotalStatus', 'flameworkTotalStatus', 'userTotalStatus', 'designTotalStatus', 'planTotalStatus'
    ])
  },
  methods: {
    changeTab(i) {
      this.$emit('change-tab', i);
      this.selectedTab = i;
    },
  },
}
</script>
<style lang="scss" scoped>
.v-navigation-drawer ::v-deep .v-navigation-drawer__content {
  &::-webkit-scrollbar{
    width: 8px;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-track{
    background: #fff;
    border: none;
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb{
    background: #ccc;
    border-radius: 10px;
    box-shadow: none;
  }
}

.v-btn-content {
  justify-content: left; 
}
</style>